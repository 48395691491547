.modal {
  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  .modal-fade-screen {
    background-color: rgba(0, 0, 0, 0.65);
    @include position(fixed, 0 0 0 0);
    opacity: 1;
    transition: opacity 0.25s ease;
    z-index: 9999999;
  }

  .modal-close {
    background-color: transparent;
    border: none;
    color: $black-base;
    cursor: pointer;
    @include position(absolute, 15px 20px null null);
    @include s-huge;

    &:focus {
      @include outline-dark;
    }

    @include tablet {
      @include position(absolute, 30px 30px null null);
    }
  }

  .modal-inner {
    background-color: $white-base;
    box-shadow: none;
    max-height: calc(100% - 40px);
    -ms-overflow-style: none;
    @include rem(margin, 20px);
    @include rem(padding, 20px);
    overflow: auto;
    position: relative;
    transition: opacity 0.25s ease;

    &::after {
      clear: both;
      content: '';
      display: block;
    }

    @include tablet {
      @include rem(padding, 30px);
      @include vertical-center;
      left: 50%;
      max-height: 750px;
      max-width: 700px;
      margin: auto;
      transform: translateY(-50%) translateX(-50%);
      width: 100%;
    }

    h2 {
      border-bottom: 1px solid #dedede;
      padding-right: 40px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      font-size: 25px;
    }

    .button-block {
      button,
      a {
        @include rem(margin-top, 10px);
        width: 100%;

        @include tablet {
          width: inherit;
        }

        & ~ button,
        & ~ a {
          @include tablet {
            @include rem(margin-left, 10px);
          }
        }
      }
    }
  }
}

//Auth Modal
.auth {
  background-color: $black-base;

  .ReactModal__Overlay {
    background-color: $black-base;
  }
}

.modal {
  .auth-card.modal-inner {
    border-radius: 5px;
    max-width: 400px;
    
    .button-group { 
      display: flex;
      flex-direction: column;
    }

    .modal-close {
      display: none;
    }

    header {
      text-align: center;
      padding: 20px 0px;

      img {
        width: 230px;
      }

      img + h2 {
        margin-top: 30px;
        padding: 0px;
        border: none;
      }

      .helper-image {
        width: 100%;
      }
    }

    .button-tertiary {
      width: 100%;
    }

    .link-secondary {
      margin-top: 15px;
      text-align: center;
      width: 100%;
    }
  }
}
