.card-preview-list {
  width: 100%;

  @include tablet {
    display: flex;
    width: calc(100% + 20px);
    margin: 0px -10px;
    flex-wrap: wrap;
  }

  a {
    display: block;
    text-decoration: none;
    white-space: normal;
    margin: 20px 10px 30px;

    @include tablet {
      width: calc(100% - 20px);
      flex: 1;
      min-width: 0;
    }

    &:hover {
      transform: scale(1.05);
    }
  }
}

.card-preview {
  text-align: center;
  width: 100%;
  height: 100%;

  p,
  li {
    font-size: 12px;
    line-height: 1.5;
  }

  h2.title {
    font-size: 20px !important;
    margin-bottom: 20px;
  }

  .preview-image {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto 20px;

    img {
      margin: 0px auto 0px;
      display: block;
      height: auto;
      max-height: 70px;
      width: auto;
      max-width: 90%;
    }
  }

  .inline-list {
    margin-bottom: 15px;
    justify-content: center;

    li {
      font-size: 12px;
    }
  }

  span.offer {
    @include font-type($primary-sans-serif-bold, normal, normal);
    font-size: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
    min-width: 0;
    display: block;
  }

  span.offer + p {
    margin-top: 10px;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.featured-banner {
  position: relative;
  text-align: right;
  margin-right: -35px;
  margin-left: 20px;

  span {
    background-color: $yellow-base;
    padding: 5px 10px;
    width: auto;
  }

  &:before {
    content: '';
    border-top: 15px solid $yellow-dark;
    border-right: 15px solid transparent;
    right: 0;
    bottom: -15px;
    position: absolute;
  }
}

.featured {
  width: 100%;

  .featured-banner {
    margin-top: -10px;
    margin-left: auto;
  }
}

.category-preview-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: calc(100% + 20px);
  margin: 0px -10px;
}

.background-image {
  position: relative;
  text-align: center;
  width: calc(50% - 20px);
  min-height: 100px;
  margin: 20px 10px 0px;
  overflow: hidden;
  background-color: $black-base;

  @include tablet {
    min-height: 150px;
    width: calc(20% - 20px);
  }

  a {
    height: 100%;
    text-decoration: none;
    transition: all 0.5s ease-in-out;

    &:after {
      background-color: rgba(0, 0, 0, 0.7);
      content: '';
      position: absolute;
      top: 0px;
      width: 100%;
      display: block;
      z-index: 1;
      height: 100%;
      left: 0px;
    }

    &:hover {
      img {
        transform: scale(1.2);
      }
    }
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    transition: all 0.5s ease-in-out;
  }

  span {
    z-index: 2;
    width: 100%;
    text-align: center;
    display: flex;
    white-space: normal;
    justify-content: center;
    align-items: center;
    color: $white-base;
    line-height: 1.2;
    font-size: 18px;
  }
}

.inline-list {
  li {
    font-size: 16px;

    @include tablet {
      display: inline;
    }

    strong {
      display: inline;
      line-height: 1;
    }

    &:not(:first-child) {
      margin-top: 0px;
      &:before {
        @include tablet {
          content: '|';
          display: inline-block;
          margin: 0px 15px;
          opacity: 0.25;
        }
      }
    }
  }
}
