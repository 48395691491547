/*-----------------------
Empty State
-----------------------*/

.empty-state{
	background-color: $grey-light;
	@include center;
	@include rem(padding, 30px);
	max-width: 950px;
	text-align: center;

	img{
		background-color: lighten($grey-base, 10%);
		border-radius: 20em;
		@include rem(margin-bottom, 10px);
		@include rem(padding, 12px);
		max-width: 50px;
	}

	h1, h2, h3{
		font-size: 23px;
		margin-bottom: 10px;
	}
}