.payment-form-empty-cc {
	width: 100%;
	height: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.referred-by-wrapper {
	.referring-member-number {
		margin-bottom: 5px;
	}

	.referring-member-name {
		color: $grey-base;
		font-size: 0.9rem;
		font-weight: $bold;
	}
}
