/*-----------------------
Flash
-----------------------*/

@keyframes fade-in{
  0%{ opacity:0;  }
  100%{ opacity:1; }
}

@keyframes slide-in-left{
  0%{ opacity:0; transform: translateX(-100%); }
  100%{ opacity:1; transform: translateX(0%) rotate(15deg);}
}

@keyframes fade-in-right{
  0%{ opacity:0; transform: translateX(50%); }
  100%{ opacity:1; transform: translateX(0%);}
}

@keyframes slide-in-right{
  0%{ transform: translateX(100%); }
  100%{ transform: translateX(0%);}
}

@keyframes fade-in-left{
  0%{ opacity:0; transform: translateX(-50%); }
  100%{ opacity:1; transform: translateX(0%);}
}

@keyframes fade-in-up{
  0%{ opacity:0; transform: translateY(20%); }
  100%{ opacity:1; transform: translateY(0%);}
}

@keyframes fade-in-bottom{
  0%{ opacity:0; transform: translateY(-20%); }
  100%{ opacity:1; transform: translateY(0%);}
}


.flash-message{
  @include position(fixed, 40px 20px null 20px);
  @include rem(padding, 20px 20px);
  z-index: 5;
  background: white;
  margin: 20px auto;
  max-width: 800px;
  display: flex;
  border-left: 10px solid $green-base;
  background-color: $green-light;
  box-shadow: 1px 0px 29px rgba(0, 0, 0, .2);
  border-radius: 5px;
  animation: fade-in-bottom 0.2s ease-in;

  .text-block{
    padding-right: 20px;
    display: flex;
    align-items: center;
    margin-top: 4px;
  }

  p{
    font-weight: $bold;
    line-height: normal;
  }

  &.success{
    background-color: $green-light;

    p{
      color: $green-dark;
    }
  }

  &.failure{
    background-color: $red-light;
    border-left: 10px solid $red-base;

    p{
      color: $red-dark;
    }
  }

  button{
    margin-left: auto;
  }
}

//Animation

.flash-enter {
  opacity: 0.01;

  &.flash-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }
}

.flash-leave {
  opacity: 1;

  &.flash-leave-active {
    opacity: 0.01;
    transition: opacity 0.3s ease-in;
  }
}
