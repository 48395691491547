.notifications {
  header {
    display: flex;
    justify-content: flex-end;

    h2 {
      font-size: 20px;
    }

    button {
      display: inline;
      font-size: 12px;
      margin-left: 10px;
    }
  }

  li {
    border-left: 10px solid $blue-base;
    background-color: $blue-light;
    padding: 10px 25px;
    margin-top: 10px;

    @include tablet {
      align-items: center;
      display: flex;
    }

    &.warn {
      border-left: 10px solid $yellow-base;
      background-color: $yellow-light;
    }

    &.danger {
      border-left: 10px solid $red-base;
      background-color: $red-light;
    }

    .text-block {
      @include tablet {
        margin-right: 25px;
      }

      h3 {
        font-size: 16px;
        font-family: $primary-sans-serif-bold;
      }

      p {
        font-size: 14px;
        line-height: normal;
      }

      h3 + p {
        margin-top: 5px;
      }
    }

    p + .link-primary {
      margin-top: -10px;
      display: block;
    }

    .link-secondary {
      font-size: 12px;
      margin-top: 5px;

      @include tablet {
        flex: none;
        margin-left: auto;
        margin-top: 0px;
      }
    }
  }
}

aside {
  .notifications {
    li {
      p {
        font-family: $primary-sans-serif-bold;
        text-align: center;
        width: 100%;
      }
    }
  }
}
