//MAIN COLORS

  //BLACK
    $black-base:   #000000;
    $black-light:  lighten($black-base, 30%);
    $black-dark:   #15191C;

  //GREEN
    $green-base:   #0f8e47;
    $green-light:  lighten($green-base, 65%);
    $green-dark:   darken($green-base, 10%);

  //GREY
    $grey-light:   #DEDEDE;
    $grey-base:    #A9A9A9;
    $grey-dark:    #767676;

  //RED
    $red-base:     #D50000;
    $red-light:    lighten($red-base, 50%);
    $red-dark:     darken($red-base, 10%);

  //WHITE
    $white-light:  #F4F6F6;
    $white-base:   #FFFFFF;
    $white-dark:   #BBBCBE;

  //BLUE
    $blue-base:  #007FB0;
    $blue-light: lighten($blue-base, 60%);
    $blue-dark:  darken($blue-base, 10%);

  //YELLOW
    $yellow-base:  #FDDC2F;
    $yellow-light: lighten($yellow-base, 30%);
    $yellow-dark:  darken($yellow-base, 10%);

  //PRIMARY
    $primary-base: $black-base;
    $primary-dark: darken($primary-base, 10%);

  //SECONDARY
    $secondary-base: $yellow-base;
    $secondary-dark: darken($yellow-base, 15%);
