.tabs {
  display: flex;
  border-bottom: 5px solid $grey-light;
  width: auto;

  li {
    &:not(:last-child) {
      margin-right: 30px;
    }

    &.active {
      a:after {
        border-bottom: 5px solid $blue-base;
        content: '';
        display: inline-block;
        position: absolute;
        bottom: -5px;
        width: 100%;
      }
    }

    a {
      text-decoration: none;
      position: relative;
    }
  }
}

.action-and-tabs {
  margin-bottom: 30px;

  @include tablet {
    align-items: baseline;
    display: flex;
    flex-direction: row-reverse;
  }

  .button-secondary {
    margin-bottom: 20px;

    @include tablet {
      margin-bottom: 0px;
    }
  }

  .tabs {
    @include tablet {
      margin-right: auto;
    }
  }
}
