/*-----------------------
Fieldset
-----------------------*/
fieldset {
  display: inline-block;
  @include rem(margin-bottom, 15px);
  position: relative;
  width: 100%;
}
/*-----------------------
Labels
-----------------------*/
label {
  color: $black-base;
  display: inline-block;
  font-family: $primary-sans-serif-bold;
  @include s-base;
  font-size: 16px;
  @include rem(margin-bottom, 6px);
  text-align: left;

  i {
    font-style: italic;
    font-weight: $regular;
  }

  .required-indicator {
    color: $red-base;
  }
}

fieldset legend{
  font-family: $primary-sans-serif-bold;
  @include rem(margin-bottom, 12px);
}

/*-----------------------
Inputs
-----------------------*/
input,
select,
textarea {
  background-color: $white-base;
  border: 1px solid $white-dark;
  border-radius: 2px;
  box-sizing: border-box;
  appearance: none;
  transition: all 0.3s ease-in-out;
  @include font-type($primary-sans-serif, normal, 400);
  @include s-base;
  @include rem(padding, 0px 13px);
  width: 100%;
  -webkit-font-smoothing: antialiased;
  height: 40px;

  &::placeholder {
    color: $grey-base;
    font-style: italic;
  }

  &:focus {
    border-color: $black-dark;
    outline: none;
  }
}

/*-----------------------
Hidden Input
-----------------------*/
.hidden-field {
  height: 0px;
  width: 0px;
}

// Cypress does not provide a way (or I couldn't find it)
// to set the value of a hidden input. This is to `hide`
// the input with type="text"
.really.hidden-field {
  input {
    padding: 0px;
    border: none;
  }
}

/*-----------------------
Input Icon
-----------------------*/
.icon-label {
  position: relative;
  input {
    @include rem(padding-left, 30px);
  }

  i {
    @include position(absolute, 12px null null 10px);
    background-repeat: no-repeat;
    background-size: 15px;
    height: 20px;
    width: 20px;
  }
}

/*-----------------------
Textarea
-----------------------*/
textarea,
textarea[type='text'] {
  height: 100px;
  resize: vertical;
  @include rem(padding, 12px 13px);
}

.with-character-count {
  label {
    @include rem(padding-right, 108px);
  }

  .character-count {
    color: $black-base;
    float: right;
    @include position(absolute, 0px 0px null null);
    @include s-small;
  }
}

/*-----------------------
Select
-----------------------*/
select {
  background-image: url('../images/utility-icons/triangle-down.svg');
  background-position: right 12px top 50%;
  background-repeat: no-repeat;
  background-size: 8px;
  cursor: pointer;
  @include rem(padding-right, 32px);
  text-overflow: ellipsis;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  option:disabled {
    color: $grey-light;
    font-style: italic;
  }
}

/*-----------------------
Checkbox
-----------------------*/
h3 + .checkbox {
  margin: 10px 0px 0px;
}

.group + .button-group {
  margin-top: 10px;
}

.checkbox {
  width: 100%;

  label {
    cursor: pointer;
    float: right;
    @include rem(margin, 1px 0px 0px);
    line-height: normal;
    width: calc(100% - 32px);
    font-family: $primary-sans-serif;
    font-weight: normal;
  }
}

.checkbox + .checkbox {
  margin: 0px 0px 0px;
}

input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  transition: all 0.3s ease-in-out;
  background-color: $white-base;
  border: 1px solid $white-dark;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  @include rem(height, 20px);
  @include rem(width, 20px);
  outline: none;
  display: inline-block;
  @include rem(margin, 0px 8px 0px 0px);
  padding: 0px;

  &:focus {
    border-color: $black-base;
    outline: none;
  }

  &:checked {
    &:after {
      background-image: url('../images/utility-icons/checkmark-white.svg');
      background-size: 17px;
      content: '';
      display: block;
      height: 16px;
      transition: all 0.2s ease-in;
      @include position(absolute, 0px null null 0px);
      text-align: center;
      width: 17px;
      -webkit-position: absolute;
      -webkit-z-index: 3;
      filter: invert(1);
    }
  }
}

.CheckboxGroup {
  span {
    display: block;
  }

  fieldset {
    margin-bottom: 0px;
  }

  fieldset {
    @include tablet {
      width: 33%;
      display: inline-block;
      padding-right: 5px;
    }
  }
}

/*-----------------------
Radio Buttons
-----------------------*/
.radio-buttons {
  display: block;
  width: 100%;

  .label-input {
    display: block;
    margin-bottom: 10px;
  }

  .input-wrapper .row {
    display: flex;
    gap: 10px;
    margin-bottom: 0px;

    input[type='radio'] {
      position: absolute;
      z-index: -1;
    }

    input[type='radio']:focus ~ span {
      border-color: $black-base;
    }

    label.radio-button {
      background-color: $white-base;
      border-radius: 3px;
      display: inline-block;
      display: flex;
      overflow: hidden;
      position: relative;
      text-align: center;
      width: 100%;
    }

    span {
      background: $white-base;
      border: 1px solid $grey-base;
      border-radius: 4px;
      color: $black-base;
      cursor: pointer;
      display: block;
      @include s-base;
      font-weight: $bold;
      padding: 15px;
      width: 100%;

      &:hover {
        background-color: lighten($primary-base, 65%);
        transition: all 0.3s ease-in-out;
      }
    }

    input:checked + span {
      background-color: $primary-base;
      border-color: $primary-base;
      color: $white-base;
    }
  }
}

/*-----------------------
Radio Inputs
-----------------------*/
.radio-inputs {
  width: 100%;

  label {
    cursor: pointer;
    line-height: normal;
    display: block;
  }

  span {
    color: $black-base;
    display: inline-block;
    @include rem(margin-top, 0px);
    width: calc(100% - 36px);

    p {
      white-space: normal;
    }
  }

  input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    transition: all 0.3s ease-in-out;
    background-color: $white-base;
    border: 1px solid $grey-base;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
    @include rem(height, 20px);
    @include rem(width, 20px);
    outline: none;
    display: inline-block;
    @include rem(margin, 0px 8px 0px 0px);
    border-radius: 20em;
    vertical-align: top;
    padding: 0px;

    &:focus {
      border-color: $black-base;
      outline: none;
    }

    &:checked {
      position: relative;
      border-color: $blue-base;

      &:after {
        background-color: $blue-base;
        color: $blue-base;
        content: '';
        display: block;
        height: 14.5px;
        transition: all 0.2s ease-in;
        text-align: center;
        width: 14.5px;
        -webkit-position: absolute;
        -webkit-z-index: 3;
        border-radius: 20em;
        @include vertical-horizontal-center;
      }
    }
  }
}

/*-----------------------
Error
-----------------------*/
.error {
  input,
  textarea,
  select {
    border: 1px solid $red-base;

    &:focus {
      border-color: $black-base;
      outline: none;
    }
  }
}

.error-message,
span.error-message {
  color: $red-base;
  display: inline-block;
  @include rem(margin-top, 4px);
  @include s-tiny;
  transition: all 0.3s ease-in-out;
  border: none;
  padding: 0px;
  height: auto;

  &:first-letter {
    text-transform: capitalize;
  }
}

/*-----------------------
Input Tooltip
-----------------------*/
.tooltip-trigger:after {
  background-color: $grey-light;
  border: 1px solid $white-light;
  border-radius: 50%;
  color: $grey-base;
  content: '?';
  cursor: pointer;
  display: inline-block;
  height: 18px;
  font-size: 12px;
  margin-left: 10px;
  text-align: center;
  line-height: 1.4;
  vertical-align: text-top;
  width: 18px;
}

.tooltip-content {
  display: none;
  @include rem(margin-bottom, 8px);

  p {
    line-height: 1.5;
  }

  &.is-active {
    display: block;
  }
}

/*-----------------------
Uploader
-----------------------*/
.fileupload {
  .thumbnail {
    display: inline-block;
    @include rem(margin-right, 15px);
    width: 70px;
    vertical-align: middle;

    img {
      border-radius: 50%;
      height: 70px;
    }
  }

  .button-secondary-light {
    @include button;
    
    &:hover {
      background-color: darken($grey-light, 8%);
      label {
        cursor: pointer;
      }
    }
  
    & {
      overflow: hidden;
      position: relative;
      vertical-align: middle;
      background-color: $grey-light;
      box-shadow: none;
      border: none;
      cursor: pointer;
    }
  }

  .button-secondary-light > input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    transform: translate(-300px, 0) scale(4);
    cursor: pointer;
  }
}

.fileupload-exists .fileupload-new,
.fileupload-new .fileupload-exists {
  display: none;
}

.fileupload-preview {
  @include rem(margin, 0px 10px);
  @include s-tiny;
  vertical-align: middle;
}

.fileupload {
  .close {
    text-decoration: none;
    vertical-align: middle;
    @include s-medium;
    @include font-type($primary-sans-serif, normal, $bold);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
  }
}

/*-----------------------
Date Picker
-----------------------*/
.react-datepicker__input-container {
  width: 100%;
}

/*-----------------------
Button Group
-----------------------*/
.button-group {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;

  @include tablet {
    justify-content: flex-end;
    flex-wrap: nowrap;
    flex-direction: unset;
  }

  button {
    width: 100%;

    @include tablet {
      margin-left: 20px;
      width: auto;
    }

    &:not(:last-child) {
      margin-top: 10px;
      @include tablet {
        margin-top: 0px;
      }
    }
  }

  a {
    width: 100%;

    @include tablet {
      margin-left: 20px;
      min-width: 180px;
      width: auto;
    }
  }

  &.stacked {
    margin-top: 20px;
    display: block;

    button {
      display: block;
      margin: 10px auto 0px;
      width: 100%;
    }
  }
}

/*-----------------------
Forms
-----------------------*/
// form{
//   .row{
//     margin-bottom: -6px;
//   }
// }

.link-primary.toggle-nested-form {
  margin-bottom: 20px;

  img {
    display: inline-block;
    width: 15px;
    margin-left: 5px;
  }
}

.nested-form {
  background-color: #f8f8f8;
  padding: 20px;
  margin-bottom: 20px;
}

.button-navigation {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;

  @include tablet {
    flex-wrap: nowrap;
    flex-direction: unset;
    // align-items: center;
  }

  button {
    margin-left: auto;
    width: 100%;

    @include tablet {
      width: auto;
    }
  }

  .back-link {
    margin-top: 20px;
    width: 100%;
    justify-content: center;

    @include tablet {
      justify-content: unset;
      margin: 0px;
      width: auto;
    }
  }
}

//Progress
.progress-steps {
  margin-bottom: 20px;
  li {
    display: inline-block;

    span {
      opacity: 0.4;
    }

    &.active span {
      @include font-type($primary-sans-serif-bold, normal, bold);
      opacity: 1;
    }

    &:not(:first-child) {
      &:before {
        background-color: $grey-light;
        content: '';
        display: inline-block;
        height: 1px;
        width: 30px;
        vertical-align: 5px;
        margin: 0px 10px;
      }
    }
  }
}

//Autocomplete
.autocomplete-wrapper {
  position: relative;

  &.is-open .autocomplete {
    display: block;
  }
}

.autocomplete {
  background-color: $white-base;
  box-shadow: 1px 0px 29px rgba(0, 0, 0, 0.1);
  position: absolute;
  max-height: 300px;
  margin-top: -12px;
  overflow: auto;
  width: 100%;
  z-index: 1;
  display: none;

  li button {
    apppearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px 20px;
    transition: all 0.5s ease-in-out;
    text-align: left;
    width: 100%;

    &:hover {
      background-color: $blue-light;
    }
  }
}

//Read Only
.card .card-inner form {
  .read-only {
    @include rem(margin-bottom, 15px);

    h3 {
      @include rem(margin-bottom, 6px);
      display: inline-block;
    }
  }
}

//Option with image
.options-with-image {
  margin-bottom: 30px;
  @include tablet {
    display: flex;
  }

  .option-with-image {
    border: 1px solid $white-dark;
    width: 100%;

    &:not(:first-child) {
      margin-top: 20px;
      @include tablet {
        margin: 0px 0px 0px 20px;
      }
    }

    img {
      height: 150px;
      object-fit: cover;
      border-bottom: 10px solid $yellow-base;
    }

    .text-block {
      padding: 20px;

      p {
        margin-top: 15px;
        font-size: 12px;
        line-height: 1.5;
      }
    }

    fieldset {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0px;
    }
  }
}

.checkbox + .row {
  margin-top: 10px;
}

.row + .checkbox {
  margin-bottom: 0px;
}

.checkbox + .checkbox {
  margin-top: 10px;
}

.checkbox-group {
  margin-bottom: 20px;

  .checkbox:not(:last-child) {
    margin-bottom: 0px;
  }
}

//Read Only
input[readonly] {
  border: none;
  padding: 0px;
  height: auto;
}

input[disabled] {
  background-color: #f2f2f2;
  cursor: not-allowed;
}

//Scrollable Content
.scrollable-terms {
  border: 1px solid $white-dark;
  border-radius: 2px;
  padding: 20px;
  max-height: 200px;
  overflow: auto;
  @include rem(margin-bottom, 15px);

  h4 {
    font-size: 16px;
    font-family: $primary-sans-serif-bold;
    margin-bottom: 10px;
  }

  p,
  p a {
    font-size: 13px !important;
    line-height: 1.6;
  }
}

//Radio Group
.inline-radio-list {
  .RadioGroup {
    span {
      display: none;
    }

    fieldset {
      display: inline-block;
      width: auto;
      margin-right: 20px;

      span {
        display: inline-block;
        margin-top: 0px;
      }
    }
  }
}

.RadioGroup{
  input[type='radio'] {
    padding: 0px;
  }
}

.inline-form {
  fieldset + .row {
    margin-top: 0px;
  }

  .button-primary {
    margin-bottom: 20px;
  }
}

//Password Rules
.password-rules {
  background-color: $white-light;
  border: 1px solid $grey-base;
  @include rem(padding, 20px);
  @include rem(margin-bottom, 20px);

  li:first-child {
    @include font-type($primary-sans-serif-bold, normal, normal);
  }

  ul {
    li {
      list-style-type: disc;
      @include rem(margin-left, 15px);

      &:first-child {
        @include font-type($primary-sans-serif, normal, normal);
      }
    }
  }
}
