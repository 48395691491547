/*-----------------------
Page Header
-----------------------*/
.page-header {
  background-color: $white-base;
  border-bottom: 1px solid #e6e6e6;

  .inner {
    @include tablet {
      align-items: center;
      display: flex;
    }

    .actions {
      border-top: 1px solid #dedede;
      padding-top: 15px;
      margin-top: 15px;

      @include tablet {
        border-top: none;
        padding-top: 0px;
        margin-top: 0px;
        margin-left: auto;
      }

      a {
        font-size: 12px;
      }
    }
  }

  h1 {
    font-size: 25px;

    @include tablet {
      font-size: 35px;
    }
  }
}
