/*-----------------------
Table
-----------------------*/
table{
  background-color: $white-base;
  table-layout: fixed;
  width: 100%;

  thead{
    border-bottom: 2px solid $black-base;
  }

  tbody{
    tr{
      border-bottom: 1px solid #DEDEDE;

      &:last-child{
        border: none;
      }
    }
  }

  th, td {
    display: table-cell;
    @include rem(margin, 15px 5px);
    @include rem(padding, 14px 10px 12px 0px);
    @include s-base;
    text-align: left;
    width: inherit;
    vertical-align: middle;
  }

  th, td:before {
    color: $grey-base;
    font-weight: $bold;
    @include s-base;
  }

  th{
    color: $black-base;
    width: 100%;
  }

  th.sortable{
    cursor: pointer;
  }

  tbody{
    overflow: auto;

    tr{
      &.clickable{
        cursor: pointer;
      }
    }
  }
}

/*-----------------------
Scrollable
-----------------------*/
.scrollable-table{
  overflow: auto;
}

/*-----------------------
Filter Table Arrows
-----------------------*/
th.sortable{
  &:after{
    display: inline-block;
    font-size: 12px;
    @include rem(margin-left, 10px);
    opacity: 0.4;
  }

  &.order-descend:after {
    background-image: url('../images/utility-icons/dropdown.svg');
  }
  &.order-ascend:after{
    background-image: url('../images/utility-icons/dropdown.svg');
    transform: scaleY(-1);
    filter: FlipV;
    -ms-filter: “FlipV”;
  }
  &:not(.order-ascend):not(.order-descend):hover:after {
    background-image: url('../images/utility-icons/dropdown.svg');
    transform: scaleY(-1);
    filter: FlipV;
    -ms-filter: “FlipV”;
  }
}