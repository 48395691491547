.notification {
  border-left: 10px solid $blue-base;
  background-color: $blue-light;
  padding: 10px 25px;
  margin: 20px 0;

  @include tablet {
    align-items: center;
    display: flex;
  }

  .text-block {
    @include tablet {
      margin-right: 25px;
    }

    p {
      font-size: 14px;
      line-height: normal;
    }
  }
}
