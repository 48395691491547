// Imports
@import '../../node_modules/@launchpadlab/lp-components/lib/styles/modal';

// Settings
@import 'settings/settings';

// Base Styles
@import 'base/typography';

// Base Components
@import 'components/buttons';
@import 'components/carousel';
@import 'components/datepicker';
@import 'components/empty-state';
@import 'components/flash';
@import 'components/footer';
@import 'components/forms';
@import 'components/page-error';
@import 'components/grid';
@import 'components/modal';
@import 'components/navigation';
@import 'components/page-header';
@import 'components/server-status-overlay';
@import 'components/skip-nav-link';
@import 'components/spinner';
@import 'components/table';
@import 'components/tooltip';
@import 'components/pagination';
@import 'components/card';
@import 'components/printable-membership-card';
@import 'components/tabs';
@import 'components/card-preview';
@import 'components/payment-form';

// Project Specific Components
@import 'components/notification';
@import 'components/notifications';

// Page Styles
@import 'pages/main';
@import 'pages/styleguide';
@import 'pages/search-and-filter';

//New Stuff
.purchase-flow {
  h1,
  h2,
  h3,
  .card header h3,
  .card header.secondary-header h2,
  .card .card-inner .group-block .group-title {
    @include font-type($primary-sans-serif-condensed-bold, normal, $regular);
    text-transform: uppercase;
  }

  h1 {
    font-size: 35px;
    text-align: center;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 25px;
  }

  h3 {
    font-size: 23px;
  }

  header {
    a {
      margin-left: auto;
    }
  }

  .card {
    .card-inner {
      @include tablet {
        padding: 20px 55px 5px;
      }

      .map-content {
        h3 {
          @include font-type(
            $primary-sans-serif-condensed-bold,
            normal,
            $regular
          );
        }
      }
    }
  }

  .group-block + .button-group {
    margin-top: 20px;
  }

  .card.card-medium .button-group {
    @include tablet {
      justify-content: flex-end;
    }
  }
}

.image-and-form-layout {
  @include tablet-landscape {
    display: flex;
  }

  .split-image-block {
    height: 260px;

    @include tablet-landscape {
      height: calc(100vh - 109px);
      width: 25%;
      position: fixed;
      bottom: 0px;
      left: 0px;
    }

    img {
      object-fit: cover;
      height: 100%;
    }
  }

  .header-title {
    position: absolute;
    top: 0px;
    height: 260px;
    width: calc(100% - 40px);
    display: flex;
    justify-content: center;
    align-items: center;

    @include tablet-landscape {
      width: 100%;
      position: relative;
      height: auto;
      padding: 0px;
      margin-bottom: 30px;
    }

    h1 {
      background-color: rgba(0, 0, 0, 0.75);
      color: $white-base;
      padding: 30px 20px 20px;
      width: 100%;
      margin: 0px;
      max-width: 400px;
      font-size: 25px;

      @include tablet-landscape {
        background-color: transparent;
        color: $black-base;
        font-size: 35px;
        padding: 0px;
        max-width: 100%;
      }
    }
  }

  .form-with-aside-wrapper,
  .form-block-container {
    padding: 20px;

    @include tablet {
      padding: 50px 30px;
    }

    @include tablet-landscape {
      margin-left: 25%;
    }
  }
}

.form-with-aside-wrapper {
  @include tablet-landscape {
    width: 75%;
  }

  .header-title {
    @include desktop {
      max-width: 1100px;
      margin: 0 auto 30px;
    }

    h1 {
      @include desktop {
        text-align: left;
      }
    }
  }

  .form-with-aside-block {
    max-width: 1100px;
    margin: 0 auto;
    justify-content: center;

    @include tablet-landscape {
      display: block;
    }

    @include desktop {
      display: flex;
    }

    .form-with-aside {
      width: 100%;
    }
  }

  aside {
    margin-top: 20px;

    @include tablet-landscape {
      width: 100%;
      margin: 30px 0px 0px;
    }

    @include desktop {
      margin: 0px 0px 0px 30px;
      width: 320px;
      flex: none;
    }
  }
}

.purchase-flow {
  .form-block-container {
    width: 100%;
    max-width: 100%;

    .card {
      max-width: 800px;
      margin: 0 auto;
      display: block;
      width: 100%;
    }
  }
}

.questionnaire-flow {
  width: 100%;
  max-width: 100%;

  .questionnaire-flow-inner {
    max-width: 800px;
    margin: 0 auto;
    display: block;
    width: 100%;
  }

  .button-group {
    .icon-button:not(:first-child) {
      @include tablet {
        margin-left: 20px;
      }
    }
  }
}

.unavailable-step {
  background-color: $grey-light;

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  h3 {
    font-size: 23px;
  }

  img {
    filter: grayscale(100%) contrast(70%);
  }
}

.unavailable-step,
.completed-step {
  .card-inner {
    display: none;
  }
}

.form-with-aside-wrapper .form-with-aside section:not(:first-child) {
  border: none;
  padding-top: 0px;
  margin-top: 20px;
}

.product-block {
  border: 1px solid $grey-light;
  margin-bottom: 30px;

  .product-block-inner {
    padding: 20px 30px;
  }

  img {
    object-fit: cover;
    height: 240px;
    border-bottom: 10px solid $yellow-base;
  }

  .description {
    border-top: 1px solid $grey-light;
    margin: 20px 0px 0px;
    padding-top: 20px;
  }
}

.card .card-inner {
  .product-block + form {
    button {
      margin-bottom: 30px;
    }

    .row {
      margin-top: 0px;
    }
  }

  .product-block {
    p + ul {
      margin-top: 20px;
    }
  }
}

.row + ul.with-checkmark-list {
  margin-bottom: 20px;

  li {
    line-height: normal;
  }
}

.card .card-inner .product-details {
  display: flex;
  gap: 10px;

  h3 {
    @include font-type($primary-sans-serif-condensed-bold, normal, $regular);
    text-transform: uppercase;
    font-size: 14px;

    @include tablet {
      font-size: 20px;
    }
  }

  ul li {
    font-size: 12px;
    line-height: normal;

    @include tablet {
      font-size: 14px;
    }
  }

  .product-details-block {
    display: flex;
  }

  .promo-code-block {
    padding-top: 20px;

    .text-block {
      padding-top: 5px;
    }
  }

  .price-block {
    margin-left: auto;
    display: flex;
    align-items: center;

    .strikethrough {
      margin: 0px;
      text-decoration: line-through;
      font-size: 12px;
      text-align: right;
    }
  }
}

.main-product-details {
  flex-direction: column;
}

.add-on-buttons button {
  border: 1px solid $grey-light;
  min-height: 80px;
  padding: 5px 20px;

  @include tablet {
    padding: 20px 30px;
    min-height: 105px;
  }

  .radio {
    border: 1px solid $grey-base;
    border-radius: 20em;
    @include rem(height, 20px);
    @include rem(width, 20px);
    margin-right: 10px;
    flex: none;

    @include tablet {
      margin-right: 20px;
    }
  }

  &.selected .radio {
    position: relative;
    border-color: $blue-base;

    &:after {
      background-color: $blue-base;
      color: $blue-base;
      content: '';
      display: block;
      height: 14.5px;
      transition: all 0.2s ease-in;
      text-align: center;
      width: 14.5px;
      -webkit-position: absolute;
      -webkit-z-index: 3;
      border-radius: 20em;
      @include vertical-horizontal-center;
    }
  }
}

.invoice-summary {
  .invoice-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    > * {
      max-width: 75%;
    }

    img {
      margin-right: 20px;
      width: 60px;
    }

    .title {
      @include font-type($primary-sans-serif-condensed-bold, normal, $regular);
      text-transform: uppercase;
      font-size: 14px;
      margin: 3px 0px 0px;

      @include tablet {
        font-size: 16px;
      }
    }

    .cost {
      margin-left: auto;

      .strikethrough {
        margin: 0px;
        text-decoration: line-through;
        font-size: 12px;
        text-align: right;
      }
    }

    &.total {
      background-color: $white-light;
      padding: 10px;
      margin: 0px -10px;

      .cost {
        @include font-type($primary-sans-serif-bold, normal, $regular);
      }
    }
  }
}

.membership-summary {
  header {
    display: flex;

    h4 {
      @include font-type($primary-sans-serif-condensed-bold, normal, $regular);
      text-transform: uppercase;
    }

    button {
      margin-left: auto;

      @include desktop {
        display: none;
      }
    }
  }

  #membership-summary-details {
    margin-top: 20px;
    @include desktop {
      display: block;
    }
  }

  .invoice-item {
    align-items: unset;

    span {
      font-size: 14px;
    }

    &.total {
      background-color: transparent;
      padding: 15px 0px 0px;
      margin: 0px;
      border-top: 1px solid $grey-light;
    }
  }

  a {
    text-align: center;
    margin-top: 20px;
    width: 100%;
  }
}

.divider + fieldset {
  margin-top: 20px;
}

.confirmation {
  text-align: center;

  h2 {
    margin-bottom: 15px;
  }

  .confirmation-icon {
    margin-bottom: 30px;
    max-width: 100px;
    @include center;
  }

  .card.card-medium {
    @include tablet-landscape {
      padding: 60px;
    }
  }

  .divider {
    margin-bottom: 20px;
  }

  .icon-and-text-callout {
    margin-top: 20px;
  }

  .captain h3 {
    display: block;
    @include font-type($primary-sans-serif-condensed-bold, normal, $regular);
    text-transform: uppercase;
  }

  .text-block {
    text-align: left;

    ul {
      @include rem(margin-bottom, 12px);
    }
  }

  .card + .card {
    margin-top: 30px;
  }

  .button-primary {
    margin-top: 10px;
  }
}

.icon-and-text-callout {
  background-color: $white-light;
  padding: 20px;
  display: flex;

  img {
    flex: none;
    margin: 0px 20px 0px 0px;
    width: 40px;
  }

  p {
    text-align: left;
  }
}

.digital-passes-block{
  margin-top: 30px;

  p{
    @include font-type($primary-sans-serif-bold, normal, normal);
  }

  .digital-passes{
    justify-content: center;

    button{
      img{
        height: 42px;
        max-height: 42px;
      }
    }
  }
}

.top-block {
  position: absolute;
  width: 100%;
  height: 25vh;
  background-color: black;
  top: 0;

  @include tablet-landscape {
    height: 40vh;
  }
}

.gated-content {
  min-height: 100vh;
  text-align: center;
  font-family: $primary-sans-serif-condensed-bold;

  .card-inner {
    h2 {
      color: $black-base;
    }
    p {
      padding: 0;
    }
  }

  .form-block-container {
    z-index: 1;
    position: relative;
  }

  .video-player-container {
    padding-bottom: 50px;

    .is-loading {
      height: 25vh;
    }
  }
  
  h2 {
    color: white;
    font-family: $primary-sans-serif-condensed-bold;
    text-transform: uppercase;
    padding: 25px 0 50px;
  }
  
  span {
    color: #fddc2f;
    font-size: 0.875rem;
    text-transform: uppercase;
  }
  
  span:not(:last-child) {
    margin-right: 8px;
  }
  
  p {
    line-height: 2;
    text-align: left;
  }

  p:last-child {
    margin-bottom: 25px;
  }

  button {
    margin: 25px 0;
  }
  
  @include tablet-landscape {
    padding-top: 50px;
    position: relative;
  }
}


.row {
  .label,
  .large {
    text-align: left;
  }

  .label {
    @include font-type($primary-sans-serif-bold, normal, normal);
    margin-bottom: 0px;
  }

  .large {
    font-size: 30px;
    line-height: normal;
  }
}
