.search-and-filter-block-container {
  padding-bottom: 80px;
  .container-inner {
    @include tablet {
      display: flex;
    }

    aside {
      display: none;

      header {
        display: flex;
        align-items: center;

        button {
          margin-left: auto;
          font-size: 20px;

          @include tablet {
            display: none;
          }
        }
      }

      &.is-active {
        display: block;
        position: fixed;
        top: 0px;
        background-color: $white-base;
        z-index: 3;
        left: 0px;
        height: 100vh;
        overflow: auto;
        padding: 20px;

        @include tablet {
          position: relative;
          background-color: transparent;
          padding: 0px;
          height: auto;
        }
      }

      @include tablet {
        display: block;
        flex: none;
        width: 300px;
        margin-right: 50px;
      }

      h2 {
        font-size: 20px;
        @include font-type($primary-sans-serif-bold, normal, normal);
      }

      h3 {
        font-size: 16px;
        @include font-type($primary-sans-serif-bold, normal, normal);
      }
    }

    .filter {
      bottom: 0px;
      z-index: 3;
      position: fixed;
      left: 0px;
      right: 0px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @include tablet {
        display: none;
      }

      img {
        width: 20px;
        margin-right: 10px;
      }
    }
  }
}

.filter-block {
  &:not(:first-child) {
    margin-top: 30px;
  }

  p {
    font-size: 16px;
    margin-top: 5px;
  }

  form {
    margin-top: 10px;

    button {
      min-width: 0px;
    }
  }
}

.search-form {
  position: relative;

  fieldset {
    margin-bottom: 0px;
  }

  input {
    padding-right: 50px;
  }

  .link-secondary {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
  }
}

.search-header {
  margin-bottom: 30px;

  @include tablet {
    align-items: center;
    display: flex;
  }

  h2 {
    font-size: 20px;
  }

  .link-secondary {
    margin-top: 5px;
    display: flex;
    svg {
      margin-right: 5px;
    }
  }

  form {
    flex: none;
    margin-top: 20px;
    @include tablet {
      margin-top: 0px;
      margin-left: auto;
    }

    fieldset {
      align-items: center;
      display: flex;
      margin-bottom: 0px;

      span {
        flex: none;
        margin: 0px 10px;

        label {
          margin: 0px;
        }
      }
    }
  }
}

.card-preview-list {
  @include tablet {
    display: flex;
    flex-wrap: wrap;
  }

  a {
    margin-top: 0px;

    @include tablet {
      flex: none;
    }

    @include tablet-landscape {
      width: calc((100% / 3) - 20px);
    }
  }
}

.results-block {
  @include tablet {
    width: calc(100% - 350px);
  }

  .empty-state {
    max-width: 100%;
  }
}

/*-----------------------
Radio
-----------------------*/
.RadioGroup {
  width: 100%;

  span label {
    margin-bottom: 10px;
  }

  @include tablet {
    margin-bottom: 0px;
  }

  fieldset {
    margin-bottom: 5px;

    @include tablet {
      width: 100%;
      vertical-align: top;
    }

    &:last-child {
      margin-bottom: 0px;
    }

    label {
      @include font-type($primary-sans-serif, normal, normal);
    }
  }

  label {
    cursor: pointer;
    line-height: normal;
    display: block;
  }

  span {
    color: $black-base;
    display: inline-block;
    @include rem(margin-top, 0px);
    width: calc(100% - 50px);

    p {
      white-space: normal;
    }
  }

  .input-wrapper {
    float: left;
  }

  input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    transition: all 0.3s ease-in-out;
    background-color: $white-base;
    border: 1px solid $grey-base;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
    @include rem(height, 20px);
    @include rem(width, 20px);
    outline: none;
    display: inline-block;
    @include rem(margin, 0px 8px 0px 0px);
    border-radius: 20em;
    vertical-align: top;

    &:focus {
      border-color: $black-base;
      outline: none;
    }

    &:checked {
      position: relative;
      border-color: $blue-base;

      &:after {
        background-color: $blue-base;
        color: $blue-base;
        content: '';
        display: block;
        height: 14.5px;
        transition: all 0.2s ease-in;
        text-align: center;
        width: 14.5px;
        -webkit-position: absolute;
        -webkit-z-index: 3;
        border-radius: 20em;
        @include vertical-horizontal-center;
      }
    }
  }
}
