body {
  background-color: #f8f8f8;

  &.modal-open {
    overflow: hidden;
    // Prevent scroll on ios safari
    position: fixed;
    width: 100%;
    height: 100%;
    // Prevent pull-to-refresh on ios chrome
    overscroll-behavior-y: contain;
  }
}

main {
  position: relative;

  @include tablet-landscape {
    margin-top: 110px;
  }
}

[class*='-block-container'] {
  @include base-padding;
  
  & {
    max-width: 1400px;
    margin: 0 auto;
    display: block;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

hr {
  border: none;
  border-bottom: 1px solid $white-dark;
}

//Project

.form-block-container {
  max-width: 950px;
}

.notifications + .content-with-aside-wrapper {
  margin-top: 30px;
}

//Layout
.content-with-aside-wrapper {
  @include tablet-landscape {
    display: flex;
  }

  .content-with-aside {
    margin-bottom: 30px;
    width: 100%;

    @include tablet-landscape {
      margin-bottom: 0px;
    }

    section:not(:first-child) {
      margin-top: 30px;
      padding-top: 30px;
      border-top: 1px solid #dedede;
    }

    h2 {
      margin-bottom: 20px;
      font-size: 30px;
    }
  }

  aside {
    h2 {
      margin-bottom: 20px;
      font-size: 30px;
    }

    @include tablet-landscape {
      width: 375px;
      flex: none;
    }
  }

  .content-with-aside + aside,
  aside + .content-with-aside {
    @include tablet-landscape {
      margin-left: 30px;
    }
  }
}

.divider {
  opacity: 0.25;
}

.detail-block-wrapper {
  @include tablet {
    display: flex;
  }

  .detail-block-group {
    @include tablet {
      display: flex;
    }

    .detail-block {
      @include tablet {
        width: calc(50% - 20px);
      }

      &:not(:first-child) {
        @include tablet {
          border-top: none;
          border-left: 1px solid #dedede;
          padding: 0px;
          margin-top: 0px;
          margin-left: 20px;
          padding-left: 20px;
          width: calc(50% + 20px);
        }
      }
    }
    
    .trial-detail-block {
      @extend .detail-block;
      @include tablet {
        width: 100%;
      }
      
      & {
        font-size: medium;
      }
    }
  }

  .detail-block-groups {
    @include tablet {
      width: 100%;
    }
  }

  .detail-block-groups + .detail-block {
    @include tablet {
      width: 255px;
      flex: none;
      margin-top: 0px;
      padding-top: 0px;
      border-top: none;
      border-left: 1px solid #dedede;
      margin-left: 20px;
      padding-left: 20px;
    }
  }
}

.detail-block {
  &:not(:first-child) {
    border-top: 1px solid #dedede;
    margin-top: 20px;
    padding-top: 20px;
  }
}

.detail-block {
  h4 {
    display: block;
    font-size: 16px;
    @include font-type($primary-sans-serif-bold, normal, normal);
    margin-bottom: 15px;
    display: inline;
  }

  h4 + a {
    display: inline;
  }

  .circle-image-block + p {
    margin-top: 15px;
  }

  p {
    a {
      display: inline;
      margin-left: 10px;
    }
  }

  a {
    display: block;
  }

  .link-small {
    margin-top: 10px;
  }

  button {
    margin-top: 10px;
  }

  h4 + .circle-image-block {
    margin-top: 16px;
  }
}

.contact-links .social-links,
.social-links {
  display: flex;
  justify-content: center;
  width: 100%;

  .external-link {
    &:after {
      display: none;
    }
  }

  li {
    border: none;
    margin: 15px 10px 0px;

    a {
      padding: 0px;

      img {
        width: 25px;
      }
    }
  }
}

.contact-links .social-links {
  margin-top: 0px;

  li {
    padding: 0px;
  }
}

.status-icon {
  margin-right: 10px;
  vertical-align: -2px;
  width: 15px;
}

.card .card-inner .detail-block {
  p {
    font-size: 14px;
    margin-bottom: 0px;
    line-height: 1.3;

    a:not(.link-small) {
      font-size: 14px;
      @include font-type($primary-sans-serif, normal, $regular);
      margin: 0px;
    }

    &:first-of-type {
      display: block;
      margin-top: 15px;
    }

    &:last-child {
      margin-bottom: 10px;
    }

    &.small {
      font-size: 12px;
    }
  }

  p + a {
    margin-top: 10px;
  }

  p + p {
    margin-top: 8px;
  }
}

.link-small {
  font-size: 12px;
}

//AOR
.aor-block {
  text-align: center;

  .image-block img {
    height: 165px;
    margin: -20px -20px 20px -20px;
    object-fit: cover;
    width: calc(100% + 40px);
  }

  .external-link {
    justify-content: center;
    display: block !important;
    margin-top: 8px;

    &:after{
      vertical-align: -10px;
      margin: -5px -5px 0px 0px;
      display: inline-block;
    }
  }

  a{
    white-space: normal;
  }
}

.circle-image-block {
  margin: 0 auto;

  img {
    height: auto;
    max-height: 70px;
    width: auto;
    max-width: 90%;
  }

  img.placeholder {
    border-radius: 0px;
    filter: brightness(100);
    height: auto;
    max-height: 70px;
    width: auto;
    max-width: 70px;
    padding: 25px 21px;
    object-fit: contain;
    background-color: $black-base;
    border-radius: 20em;
  }
}

h3 + .circle-image-block {
  margin: 10px 0px 0px;
}

aside {
  .circle-image-block + h2 {
    margin-top: 20px;
    font-size: 25px;
  }
}

//Summary Card (ex. Referral Credits)
.summary {
  margin-top: 50px;
  header {
    p {
      font-size: 16px;
      margin-bottom: 0px;
    }
  }
  .summary-data {
    @include font-type($primary-sans-serif-bold, normal, normal);
    margin-left: auto;
  }
}

//Image Card
.image-card {
  padding: 35px 20px;
  text-align: center;

  .image-block {
    height: 200px;
    margin: -35px -20px 40px;

    &:after {
      border-bottom: 10px solid $yellow-base;
      content: '';
      display: block;
      margin-top: -4px;
      width: 100%;
    }

    img {
      height: 200px;
      object-fit: cover;
      padding: 0px !important;
    }
  }

  h2 {
    font-size: 25px;
  }

  p.teaser {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 0px;
  }

  a {
    justify-content: center;
  }

  p {
    line-height: 1.5;
  }

  a + p {
    margin-top: 10px;
  }

  p + p {
    margin-top: -10px;
  }

  .social-links {
    li {
      margin-top: 0px;
    }
  }

  .social-links + a {
    margin-top: 15px;
  }
}

aside {
  .image-card {
    a {
      margin: 0 auto;
    }

    h2 + p,
    a + a,
    ul + p {
      margin-top: 10px;
    }

    .external-link {
      @include s-base;
    }

    .button-primary-outline {
      display: inline-block;
    }
  }
}

//Quick Links
.quick-links,
.contact-links {
  padding: 35px;

  h3 {
    display: block;
    font-family: $primary-sans-serif;
    font-weight: normal;
    font-size: 25px;
    text-align: center;
    width: 100%;
  }

  ul {
    margin-top: 20px;

    li {
      border-top: 1px solid $grey-light;
      padding: 10px 0px;

      a {
        display: inline-block;
        text-decoration: none;
        width: 100%;
        white-space: break-spaces;
      }

      &:last-child a {
        padding-bottom: 0px;
      }
    }
  }
}

//Contact Links
.contact-links {
  h3 {
    text-align: left;
  }

  li {
    align-items: center;
    display: flex;

    img {
      flex: none;
      width: 40px;
    }

    img + p,
    img + a {
      margin-left: 10px;
    }
  }
}

.inline-form {
  .with-checkmark-list {
    li {
      margin-bottom: 10px;
    }
  }
}

.with-checkmark-list {
  li {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:before {
      content: '';
      display: inline-block;
      margin-right: 10px;
      background-image: url('../images/utility-icons/completed.svg');
      background-repeat: no-repeat;
      background-size: 15px;
      height: 15px;
      width: 15px;
      flex: none;
      margin-top: 2.5px;
    }

    p,
    button,
    a {
      font-size: 14px !important;
    }
  }
}

//Accordion
.accordion-block-minimal {
  &.is-open {
    .trigger button img {
      transform: rotate(180deg);
    }
  }

  .accordion-header button {
    background-color: #f8f8f8;
    border-top: 1px solid $grey-light;
    margin: 20px -20px -20px;
    padding: 10px 20px;
    justify-content: center;
    display: flex;
    width: calc(100% + 40px);
    font-size: 16px;
    align-items: center;
    padding: 15px;

    img {
      margin-left: 10px;
      transition: all 0.3s ease-in-out;
      width: 15px;
    }

    &:hover {
      background-color: darken(#f8f8f8, 10%);
      border-top: 1px solid $grey-light;
      color: $black-base;
    }
  }

  .accordion-header + .accordion-content {
    margin-top: 40px;
    background-color: #f8f8f8;
    margin: 20px -20px -20px;
    padding: 20px;
  }
}

.terms {
  p,
  a {
    font-size: 14px !important;
  }

  p a {
    display: inline;
  }
}

.note {
  background-color: $blue-light;
  padding: 10px 20px;
  margin-bottom: 20px;

  p {
    @include font-type($primary-sans-serif-bold, normal, normal);
  }
}

.map {
  &.small {
    height: 125px;
    width: 150px;
  }

  &.large {
    height: 350px;
    margin-bottom: 20px;
    width: 100%;
  }
}

.map-content {
  margin-top: 15px;

  .address {
    margin-top: 6px;
  }
}

.group-block {
  .map {
    height: 300px;
    width: 100%;
  }
}

.flex-horizontal {
  display: flex;
  flex-direction: row;

  &.flex-reverse {
    flex-direction: row-reverse;
  }
}

.flex-vertical {
  display: flex;
  flex-direction: column;

  &.flex-reverse {
    flex-direction: column-reverse;
  }
}

fieldset.membership-type-options {
  margin-bottom: 0px;

  fieldset {
    margin-bottom: 0px;
  }

  fieldset + fieldset {
    @include rem(margin-top, 15px);
  }
}

fieldset .membership-type-options {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 15px;
  padding: 10px;
  border: 1px solid $grey-light;
  position: relative;

  .product-details {
    margin-bottom: 0px;
    height: 80px;

    .price-block {
      padding-right: 15px;
    }
  }

  img {
    display: none;

    @include tablet {
      position: absolute;
      border-right: 10px solid $yellow-base;
      top: 0px;
      bottom: 0px;
      left: 0px;
      height: 100%;
      width: 160px;
      object-fit: cover;
      display: block;
    }
  }

  .input-wrapper {
    @include tablet {
      padding-left: 170px;
    }
  }

  label {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.price {
  flex: none;
  white-space: nowrap;
  @include font-type($primary-sans-serif-condensed-bold, normal, $regular);
  text-transform: uppercase;

  span {
    margin-top: 0px;

    @include tablet {
      font-size: 25px;
    }
  }

  .time {
    font-size: 14px;
  }
}

.add-on-buttons {
  gap: 15px;

  button.button-primary-outline {
    margin: 0;
    width: 100%;
    text-align: left;

    &:hover {
      background: initial;
      border-color: $blue-base;
    }

    &.selected {
      background: $blue-light;
      border-color: $blue-base;
    }

    .flex-horizontal {
      align-items: center;
      justify-content: space-between;
    }
  }
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0);
  border: 0 !important;
}

.with-line-breaks {
  white-space: pre-line;
}

// Legal section for trials

.trial-legal-language {
  padding-bottom: 5%;
}
