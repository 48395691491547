/*-----------------------
Navigation
-----------------------*/
.page-navigation {
  background-color: $black-base;
  border-bottom: 10px solid #fddc2f;
  width: 100%;
  z-index: 2;
  
  &.no-bottom-border {
    border-bottom: 15px solid $black-base;
  }

  @include tablet-landscape {
    position: fixed;
    top: 0px;
  }

  .page-navigation-block-container {
    @include rem(padding, 15px 20px);

    @include tablet {
      @include rem(padding, 28.5px 30px);
    }
  }

  .inner {
    align-items: center;
    display: flex;

    .logo {
      flex: none;

      img {
        width: 150px;

        @include tablet {
          width: 230px;
        }
      }
    }
  }

  .navigation-links {
    margin-left: auto;
    display: flex;
    align-items: center;

    li {
      display: none;
      margin-left: 30px;
      position: relative;

      @include tablet {
        display: inline-block;
      }

      a {
        color: $white-base;
        text-decoration: none;
        display: flex;
        align-items: center;

        svg {
          flex: none;
          height: 22px;
          margin-right: 10px;
          width: 22px;
        }
      }

      &.account-link {
        display: inline-block;

        button {
          padding: 0px;
          min-width: 100%;
          display: flex;
          align-items: center;
        }

        span {
          display: none;

          @include tablet {
            display: inline-block;
            margin-right: 10px;
            line-height: 1;
          }
        }

        .navigation-icon {
          margin-right: 10px;
          width: 22px;
          height: 22px;
        }

        .dropdown {
          width: 15px;
        }
      }
    }
  }
}

.account-link .account-block {
  background-color: $white-base;
  box-shadow: 1px 0px 29px rgba(0, 0, 0, 0.1);
  max-width: 370px;
  position: absolute;
  top: 40px;
  right: 0px;
  z-index: 1;
  display: none;

  &.is-open {
    display: block;
  }

  @include tablet {
    width: 370px;
  }

  .account-block-inner {
    padding: 20px;
  }

  .user {
    text-align: center;

    span.initials {
      background-color: $yellow-base;
      border-radius: 20em;
      height: 40px;
      line-height: 3.1;
      width: 40px;
      margin: 0px;
      margin-bottom: 10px;
      font-family: $primary-sans-serif-bold;
      display: inline-block;
    }

    p {
      margin-bottom: 0px;

      &:first-of-type {
        font-family: $primary-sans-serif-bold;
      }
    }
  }

  .account-links {
    margin-top: 20px;

    li {
      border-top: 1px solid #dedede;
      margin: 0px;
      width: 100%;
      display: block;

      img {
        width: 40px;
        margin-right: 5px;
        padding: 8px;
        height: 40px;
        margin-top: -4px;
      }

      a {
        color: $black-base;
        padding: 15px 0px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .account-block-inner + .link-primary {
    appearance: none;
    background-color: transparent;
    border: none;
    border-top: 1px solid #dedede;
    color: $black-base !important;
    justify-content: center;
    margin-top: -20px;
    padding: 15px;
    text-align: center;
    width: 100%;
    text-decoration: none;
    font-size: 16px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.dropdown-wrapper{
  position: relative;
}

.dropdown-block{
  background-color: $white-base;
  box-shadow: 1px 0px 29px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 30px;
  right: 0px;
  width: 175px;
  z-index: 1;
  display: none;

  &.is-open {
    display: block;
  }

  .dropdown-block-inner {
    padding: 15px;
  }
}

//Subnav
.mobile-subnav {
  align-items: center;
  background-color: $black-base;
  display: flex;
  justify-content: flex-end;
  padding: 15px 20px 2px;

  @include tablet {
    display: none;
  }

  li {
    margin-left: 30px;
    a {
      color: $white-base;
      text-decoration: none;
      font-size: 14px;
    }
  }
}
