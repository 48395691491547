//Membership Card

.membership-card {
  background-color: $white-base;
  border: 1px solid $grey-light;
  border-radius: 10px;
  display: block;
  height: 160px;
  margin: 50px auto;
  overflow: hidden;
  position: relative;
  width: 247px;

  @include tablet {
    transform: scale(2);
    transform-origin: top;
  }

  .membership-card-content {
    display: flex;
    padding: 0px 13px;

    p {
      margin-bottom: 0px;
      line-height: normal;
    }

    .label-and-value {
      margin-bottom: 8px;

      .label {
        color: rgb(147, 149, 152);
        font-size: 7px;
        font-family: $primary-sans-serif-condensed;
      }

      .value {
        font-family: $primary-sans-serif-condensed-bold;
        font-size: 7.5px;
        letter-spacing: 0.5px;
      }
    }
  }

  .member-card-body {
    width: 70%;
    margin-top: 18px;

    .membership-type {
      color: rgb(255, 203, 5);
      font-family: $primary-sans-serif-bold;
      font-size: 7px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    .membership-number {
      font-family: $primary-sans-serif-condensed-bold;
      font-size: 10.6px;
      margin-bottom: 8px;

      span {
        font-size: 14px;
        letter-spacing: 1.25px;
      }
    }

    .name {
      font-family: $primary-sans-serif-bold;
      font-size: 9px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .member-card-sidepanel {
    margin-top: 8px;
    text-align: right;
    width: 34%;

    .label-and-value {
      margin-bottom: 6px;

      .label {
        font-style: italic;
        text-transform: uppercase;
      }

      .value {
        font-size: 8px;
      }
    }
  }

  .emergency-text {
    color: rgb(197, 134, 43);
    font-family: $primary-sans-serif-condensed-bold;
    font-size: 6.9px;
    text-transform: uppercase;
  }
}

.print-and-view {
  button {
    display: block;
    margin: 30px auto;

    @include tablet {
      margin: 200px auto 30px;
    }
  }
}

@media print {
  .print-and-view {
    button {
      display: none;
    }
  }
}
