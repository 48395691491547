// Buttons Component Styles
// ========================================

// Main Button
@mixin button {
  display: inline-block;
  font-family: $primary-sans-serif-bold;
  font-weight: normal;
  @include rem(padding, 10px 15px 7px);
  font-size: 16px;
  transition: all 0.5s ease-in-out;
  min-width: 100px;
  position: relative;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  min-height: 40px;

  &:hover {
    cursor: pointer;
  }
}

// Button Styles
@each $button in $buttons {
  $type: nth($button, 1);
  $bg-1: nth($button, 2);
  $border: nth($button, 3);
  $color: nth($button, 4);
  $hover-bg: nth($button, 5);
  $hover-color: nth($button, 6);

  .button-#{$type},
  a.button-#{$type} {
    background-color: $bg-1;
    border: 1px solid $border;
    color: $color;
    @include button;

    &:hover {
      background-color: $hover-bg;
      border: 1px solid $hover-bg;
      color: $hover-color;
    }

    &:active {
      background-color: $hover-bg;
      border: 1px solid $hover-bg;
      color: darken($hover-color, 5%);
    }

    &.is-disabled {
      background-color: $bg-1;
      color: $color;
      cursor: not-allowed;
      opacity: 0.4;

      &:hover {
        opacity: 0.4;
      }
    }

    &.in-progress {
      color: transparent;
      pointer-events: none;
      position: relative;

      &:after {
        animation: spin 0.5s linear infinite;
        border: 2px solid $color;
        border-radius: 50%;
        border-right-color: transparent;
        border-top-color: transparent;
        content: '';
        display: block;
        height: 20px;
        @include position(absolute, 7px null null null);
        margin: 0 calc((100% - 40px) / 2);
        width: 20px;
      }
    }
  }

  // Links
  .link-#{$type} {
    background-color: transparent;
    border: none;
    color: $bg-1;
    display: inline-block;
    @include s-base;
    @include font-type($primary-sans-serif-bold, normal, normal);
    transition: all 0.3s ease-in-out;
    @include rem(margin-bottom, 20px);
    padding: 0px;
    text-decoration: none;
    cursor: pointer;
    height: auto;
    min-height: auto;

    &:hover {
      color: $hover-bg;
    }
  }
}

a {
  display: flex;
  flex: none;
  white-space: nowrap;
  align-items: center;
}

// Buton Sizes
.button-small {
  @include rem(padding, 5px 6px 2px);
  @include s-small;
  min-width: inherit;
  width: 100px;
  min-height: 30px;
}

.button-large {
  @include rem(padding, 15px 12px);
  height: 52px;
}

//Back Link
.back-link {
  align-items: center;
  display: flex;
  font-size: 12px;

  svg {
    font-size: 20px;
    margin-right: 5px;
  }
}

//Special Buttons
[class*='-button-icon'] {
  &:before {
    content: '';
    background-position: right 0px top 50%;
    background-repeat: no-repeat;
    background-size: 40px;
    @include rem(padding-right, 32px);
    position: absolute;
    top: 0px;
    bottom: 0px;
    min-height: 39px;
    width: 40px;
    padding: 0px;
  }
}

[class*='-left-button-icon'] {
  &:before {
    border-right: 1px solid $black-base;
    left: 0px;
  }

  &.button-secondary {
    &:before {
      border-right: 1px solid $white-base;
    }
  }
}

[class*='-right-button-icon'] {
  &:before {
    border-left: 1px solid $black-base;
    right: 0px;
  }

  &.button-secondary,
  &.button-primary.button-secondary {
    &:before {
      border-left: 1px solid $white-base;
      filter: invert(0);
    }
  }
}

.button-primary {
  min-width: 180px;

  &:before {
    filter: invert(1);
  }

  &:hover {
    opacity: 0.75;
  }
}

[class*='-primary-outline'] {
  min-width: 120px;
  &:hover {
    opacity: 1;
    &:before {
      filter: invert(1);
    }
  }

  &.arrow-right-button-icon,
  &.arrow-left-button-icon {
    &:before {
      filter: invert(0);
    }

    &:hover {
      &:before {
        filter: invert(1);
      }
    }
  }
}

[class*='button-'][class*='-left-button-icon'] {
  @include rem(padding-left, 50px);
}

[class*='button-'][class*='-right-button-icon'] {
  @include rem(padding-right, 50px);
}

//Icons
.plus-right-button-icon,
.plus-left-button-icon {
  &:before {
    background-image: url('../images/button-icons/plus.svg');
    filter: invert(0);
  }
}

.arrow-right-button-icon,
.arrow-left-button-icon {
  &:before {
    background-image: url('../images/button-icons/arrows.svg');
  }
}

.printer-right-button-icon,
.printer-left-button-icon {
  &:before {
    background-image: url('../images/button-icons/print-light.svg');
    filter: invert(0);
    border-color: $white-base;
  }
}

.external-link {
  align-items: center;
  display: inline-flex !important;
}

.external-link.with-icon:after {
  content: '';
  background-image: url('../images/button-icons/external-link.svg');
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: inherit;
  height: 30px;
  width: 30px;
  display: inline-block;
  vertical-align: -10px;
  margin: 0px -5px 0px 0px;
}

.external-link-in-text {
  display: inline !important;
}

button.continue-link {
  align-items: center;
  display: flex !important;
  font-size: 16px;

  &:after {
    content: '';
    background-image: url('../images/button-icons/arrows.svg');
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: inherit;
    height: 30px;
    width: 30px;
    display: inline-block;
    vertical-align: -10px;
  }
}

.button-primary-outline + .button-primary-outline {
  margin-top: 10px;

  @include tablet {
    display: inline-block;
    margin: 0px 0px 0px 20px;
  }
}

.button-group-2 {
  button {
    min-width: 120px;

    &:first-child {
      margin-right: 20px;
    }
  }
}

.link-secondary {
  &:hover {
    color: $grey-dark;
  }
}

.icon-button-group {
  @include tablet {
    display: flex;
    margin-top: 20px;
  }

  .icon-button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid $grey-light;
    color: $black-base;
    flex: unset;
    width: 100%;
    margin-top: 20px;

    &:hover {
      background-color: $grey-light;
      color: $black-base;
      opacity: 1;
      border-color: $black-base;
    }

    &:not(:first-child) {
      @include tablet {
        margin-left: 20px;
      }
    }

    @include tablet {
      margin-top: 0px;
    }

    img {
      margin-right: 20px;
      width: 39px;
    }
  }
}

.text-button-group {
  .button-primary {
    background-color: transparent;
    border: 1px solid $grey-light;
    color: $black-base;
    flex: unset;
    width: 100%;
    margin-top: 20px;
    text-align: left;

    &:hover {
      background-color: $grey-light;
      color: $black-base;
      opacity: 1;
      border-color: $black-base;
    }
  }
}

.text-button-group {
  .button-primary {
    padding: 20px 30px;

    .text-block {
      h3 {
        @include font-type(
          $primary-sans-serif-condensed-bold,
          normal,
          $regular
        );
        text-transform: uppercase;
        font-size: 20px;
      }

      p {
        font-size: 14px;
        margin-bottom: 0px;
      }
    }
  }
}
