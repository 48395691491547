.carousel {
  .slick-dots {
    bottom: 0;
    position: relative;
  }

  .registration-image {
    padding: 10px;
  }
}
