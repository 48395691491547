.card {
  background-color: $white-base;
  box-shadow: 1px 0px 29px rgba(0, 0, 0, 0.1);
  padding: 20px;

  &.centered {
    text-align: center;
  }

  &.card-medium {
    padding: 40px 30px;

    @include tablet {
      padding: 60px 20px;
    }

    .button-group {
      justify-content: unset;
      margin-top: 20px;

      button {
        margin-left: 0px;
      }
    }

    .nested-form .button-group {
      justify-content: flex-end;
      margin-top: 0px;

      button {
        @include tablet {
          margin-left: 20px;
          width: auto;
        }
      }
    }
  }

  &.card-small {
    &.error {
      max-width: 700px;

      h2 {
        font-size: 1.875rem;
        padding: 1rem 0;
      }

      p { 
        text-align: center;
      }

      img {
        max-height: 150px;
      }
    }

    padding: 40px 30px;
    max-width: 500px;
    margin: 0 auto;

    @include tablet {
      padding: 50px;
    }

    header {
      display: block;

      img.icon {
        display: block;
        margin: 0 auto 30px;
        height: 80px;
        width: 80px;
      }
    }

    header + p {
      margin-top: 15px;
    }

    header + form {
      margin-top: 30px;
    }
  }

  header {
    display: flex;
    align-items: center;

    .card-icon {
      width: 80px;
      margin-right: 20px;
    }

    .icon {
      background-color: #fddc2f;
      border-radius: 20em;
      margin-right: 15px;
      height: 40px;
      width: 40px;
    }

    .icon-and-header {
      align-items: center;
      display: flex;
    }

    h2,
    h3 {
      font-size: 18px;
      @include font-type($primary-sans-serif, normal, $regular);

      @include tablet {
        font-size: 23px;
      }
    }

    .actions {
      margin-left: auto;
      display: flex;
      align-items: center;

      a {
        margin-right: 10px;
      }

      .link-small {
        img {
          margin-right: 10px;
          max-height: 20px;
          width: 30px;
        }
      }
    }

    &.secondary-header {
      border: none;
      display: block;
      margin-bottom: 30px;

      h2 {
        font-size: 25px;

        @include tablet {
          font-size: 30px;
        }
      }

      p {
        margin-top: 10px;
      }

      a {
        margin-bottom: 20px;
      }
    }
  }

  header + .card-inner {
    border-top: 1px solid #dedede;
    margin-top: 15px;
    padding-top: 20px;
  }

  h3 {
    font-size: 16px;
    font-family: $primary-sans-serif-bold;
    display: inline;

    span {
      font-family: $primary-sans-serif;
      font-weight: normal;
    }
  }

  .card-inner {
    h3 {
      font-size: 16px;
      font-family: $primary-sans-serif-bold;
      display: inline;

      span {
        font-family: $primary-sans-serif;
        font-weight: normal;
      }
    }

    h3 + p,
    button + p,
    a + p {
      margin-top: 5px;
    }

    .inner-text-action {
      display: block;
      padding-top: 10px;

      @include tablet {
        display: flex;
      }

      button {
        margin-bottom: 20px;
        min-width: fit-content;
        padding: 10px;
      }

      p {
        margin-bottom: 10px;
      }
    }

    p {
      font-size: 16px;

      &.large {
        font-size: 30px;
        line-height: normal;
      }
    }

    .form-block,
    form {
      margin: 0 auto;
      max-width: 730px;
    }

    .group {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }
}

.card + .card {
  &:not(:first-child) {
    margin-top: 20px;
  }
}

.link-primary {
  min-width: auto;
  text-decoration: underline;
  margin-bottom: 0px;
  color: $blue-base;
  font-size: 12px;

  &:hover {
    background-color: transparent;
    border: none;
    color: $blue-dark;
  }
}

h3 + .link-primary,
h4 + .link-primary {
  margin-left: 5px;
}

.link-secondary {
  min-width: auto;
  text-decoration: underline;
  margin-bottom: 0px;
  // margin-left: 5px;
  color: $black-base;
  font-size: 12px;

  &:hover {
    background-color: transparent;
    border: none;
  }
}

//Or Divider
.or-divider {
  position: relative;
  text-align: center;
  margin: -10px 0px 20px;

  span {
    background-color: $white-base;
    position: relative;
    padding: 0px 20px;
    z-index: 1;
    width: auto;

    font-size: 12px;
  }

  &:after {
    content: '';
    border-bottom: 1px solid #dedede;
    position: absolute;
    top: 14px;
    display: block;
    width: 100%;
  }
}

//Switch
.switch-wrapper {
  display: flex;
  align-items: center;

  p {
    line-height: 1.4;
    margin-bottom: 0px;
    text-align: right;
  }

  p + .switch {
    margin: 0px 0px 0px 10px;
  }

  .switch + p {
    margin: 0px 0px 0px 10px;
  }

  .switch + p {
    text-align: left;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  flex: none;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider.checked {
  background-color: #007fb0;
}

.slider.locked {
  cursor: not-allowed;
  opacity: 0.5;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.slider.checked:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

//Accordion

p + .accordion-block {
  border-top: 1px solid #dedede;
  padding-top: 15px;
}

.accordion-block {
  &:not(:last-child) {
    border-bottom: 1px solid #dedede;
    padding-bottom: 15px;
    margin-bottom: 20px;
  }

  .accordion-header {
    display: flex;
    align-items: center;

    .header {
      @include tablet {
        display: flex;
        align-items: center;
        width: 100%;
      }

      p {
        font-size: 14px;
        margin-bottom: 0px;
      }

      .actions {
        @include tablet {
          display: flex;
          align-items: center;
          margin-left: auto;
        }

        button,
        a {
          margin: 0px 20px 0px 0px;

          @include tablet {
            margin: 0px 0px 0px 20px;
          }
        }
      }
    }

    .trigger {
      margin-left: auto;

      button {
        margin-left: 20px;
      }
    }
  }

  .content {
    border-top: 1px solid #dedede;
    margin-top: 15px;
    padding-top: 10px;

    p {
      margin: 5px 0px 0px;
    }

    h3 {
      display: block;
      margin: 5px 0px 10px;
    }

    form {
      padding: 10px 0px;
    }

    .content-with-thumbnail {
      display: flex;
      align-items: center;

      .map {
        flex: none;
        margin-right: 15px;
        height: 125px;
        width: 125px;
      }

      .map-content {
        margin-top: 0px;

        .address {
          margin-top: 0px;
        }
      }
    }
  }

  &.is-open {
    .trigger button {
      transform: rotate(180deg);
    }
  }
}

.align-right a {
  @include tablet {
    display: inline-block;
    text-align: right;
    width: 100%;
  }
}

//List
.list {
  // margin-top: -20px;
  @include tablet {
    margin-top: 0px;
  }
  li {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #dedede;
  }
  h3 {
    font-size: 16px;
    font-family: $primary-sans-serif-bold;
  }
}

//Status
.statuses {
  display: block;
  margin-bottom: 10px;

  .status-indicator {
    display: inline-block;
    width: auto;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.status-indicator {
  align-items: center;
  display: flex;

  &:before {
    content: '';
    background-color: $green-base;
    height: 10px;
    width: 10px;
    display: inline-block;
    margin-right: 10px;
    border-radius: 20em;
    margin-top: -2px;
  }

  &.ok {
    &:before {
      background-color: $green-base;
    }
  }

  &.warning {
    &:before {
      background-color: $yellow-base;
    }
  }

  &.not-ok {
    &:before {
      background-color: $grey-base;
    }
  }

  p {
    @include font-type($primary-sans-serif-bold, normal, normal);
    font-size: 12px;
    line-height: normal;
    display: inline-block;
  }
}

//Stacked
header.with-actions.with-card-preview {
  display: block;
 
  @include tablet{
    display: flex;
  }

  &.dashboard{
    @include tablet{
      display: block;
    }

    @include desktop{
      display: flex;
    }

  }
}

header.with-actions {
  @include tablet {
    display: flex;
    align-items: center;
  }

  h2.title,
  h3.title {
    margin-bottom: 0px;
  }

  h3.title {
    margin-bottom: 0px;
    @include font-type($primary-sans-serif-bold, normal, normal);
    font-size: 20px;
  }

  .icon-and-header {
    h3 {
      @include font-type($primary-sans-serif-bold, normal, normal);
    }
  }

  .actions > * {
    margin: 5px 0;
  }

  .actions > *:first-child {
    margin-top: 0;
  }
  
  .actions > *:last-child {
    margin-bottom: 0;
  }

  .actions {
    display: block;
    margin-top: 15px;

    @include tablet {
      margin-left: auto;
      display: flex;
      align-items: center;
      margin-top: 0px;
    }

    .status-indicator {
      margin: 0px 0px 10px;
      @include tablet {
        margin: 0px 20px;
      }
    }

    .button-primary,
    .button-secondary {
      width: 100%;

      @include tablet {
        width: auto;
      }
    }

    a,
    button {
      margin-right: 20px;

      @include tablet {
        margin: 0px 0px 0px 20px;
      }

      &:first-child{
        @include tablet {
           margin: 0px 0px 0px 20px;
        }
      }
    }

    .switch-wrapper {
      flex-direction: row-reverse;
      justify-content: flex-end;

      @include tablet {
        flex-direction: unset;
      }

      .switch {
        margin: 0px 10px 0px 0px;

        @include tablet {
          margin: 0px 0px 0px 10px;
        }
      }
    }
  }

  &.dashboard{
    .actions {

      @include tablet {
        margin-top: 15px;
      }

      @include desktop {
        margin-top: 0px;
      }

      a,
      button {
        &:first-child{
          @include tablet {
            margin: 0px;
          }

          @include desktop {
            margin: 0px 0px 0px 20px;
          }
        }
      }

      a + div .dropdown-wrapper{
        @include tablet {
          margin: 0px 0px 0px 20px;
        }

        @include desktop {
          margin: 0px;
        }
      }

      .dropdown-block-inner {
        button{
          margin: 15px 0px 0px 0px;
          width: 100%;

          img{
            max-height: unset;
          }

          &:first-child{
            margin-top: 0px;
          }
        }
      }
    }
  }
}

header.with-actions .actions .dropdown-wrapper {
  .digital-pass a {
    margin: 0;
  }
  
  @include mobile {
    display: none;
  }

  @include tablet {
    margin: 0 0 0 20px;
  }

  @include desktop {
    margin: 0;
  }
}

header.with-actions .actions .digital-passes,
.digital-passes {
  display: flex;
  margin-top: 10px;

  .digital-pass {
    img {
      height: 42px;
      max-height: 42px;
    }
  
    &:last-child:not(:only-child) {
      margin-left: 20px;
    }

    &:only-child {
      a.external-link {
        width: auto;
      }
    }
  }
}

header.with-actions .actions .digital-pass a {
  width: 100%;

  &:last-child {
    margin-left: 20px;
  }

  img {
    @include mobile {
      height: 42px;
      max-height: 42px;
    }
  }
}

header.with-actions .actions .digital-passes {
  @include tablet {
    display: none;
  }
}

header.with-actions + .card-preview-list {
  margin-top: 20px;
}

header + .group-block {
  margin-top: 30px;
}

.card .card-inner {
  .group-block {
    &:not(:first-child) {
      margin-top: 30px;
    }

    &.medium-spacing {
      @include tablet {
        margin-top: 50px;
      }
    }

    .group-title {
      &:not(.home-port) {
        margin-bottom: 10px;
        font-size: 20px;
        display: block;
        @include font-type($primary-sans-serif, normal, $regular);
      }
      
      & {
        font-size: 16px;
      }
    }
  }
}

.card {
  header.with-actions + .card-inner {
    .group-block.medium-spacing {
      @include tablet {
        margin-top: 0px;
      }
    }
  }
}

.cards-title {
  font-size: 30px;
  margin-bottom: 20px;
}

.with-card-preview {
  .card-icon {
    margin: -10px 0px;
  }

  h3 {
    font-size: 20px;
    @include font-type($primary-sans-serif-bold, normal, normal);
    margin-bottom: 3px;
  }

  .subheader {
    @include tablet {
      display: flex;
      align-items: center;
    }

    p {
      margin-bottom: 0px;
    }

    p + .status-indicator {
      margin-left: 5px;
    }
  }
}

.card.with-image-header {
  padding: 0px;

  &.capped-image img {
    height: 290px;
    object-fit: cover;
  }

  img {
    border-bottom: 10px solid $yellow-base;
  }

  header {
    display: block;
    margin-bottom: 30px;

    h2 + p {
      margin-top: 10px;
    }
  }

  .card-inner {
    @include center;
    max-width: 730px;
    padding: 40px 30px;

    @include tablet {
      padding: 60px 20px;
    }
  }
}

.card.with-inline-image {
  @include tablet {
    align-items: center;
    display: flex;
    position: relative;
    min-height: 300px;
  }

  .image-block {
    &:after {
      @include tablet {
        display: none;
      }
    }

    @include tablet {
      width: 40%;
      border-bottom: none;
      border-right: 10px solid $yellow-base;
      height: 100%;
      margin: 0px 0px 0px -20px;
      position: absolute;
    }

    img {
      @include tablet {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .text-block {
    @include tablet {
      width: calc(60% + 45px);
      text-align: left;
      margin: 0 auto;
      padding-left: 30px;
      margin-left: 40%;
    }

    h3 {
      display: block;
      @include font-type($primary-sans-serif, normal, $regular);
      font-size: 30px;
      margin-bottom: 10px;
    }
  }
}

.offer-details {
  h4 + p {
    margin: 10px 0px 0px;
  }

  p + a {
    display: block;
    margin-top: 10px;
  }

  .external-link {
    display: block !important;
    white-space: normal;
    word-break: break-word;

    &:after {
      margin-top: -15px;
    }
  }
}

.horizonal-cards-list {
  li {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}
