@mixin mobile {
  @media only screen and (max-width: 719px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 720px) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1400px) {
    @content;
  }
}
