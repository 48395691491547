/*-----------------------
Column Width
-----------------------*/

.row {
  @include tablet {
    display: flex;
    width: 100%;
  }

  @for $i from 1 through 12 {
    .col-#{$i} {
      @include tablet {
        width: calc(($i/12) * 100%);
      }

      &:first-child {
        margin-left: 0px;
      }

      &:not(:first-child) {
        margin-top: 20px;
        @include tablet {
          margin-top: 0px;
        }
      }
    }
  }
}

.read-only {
  .row {
    @include rem(margin-bottom, 20px);
  }
}

.row:last-child {
  margin-bottom: 0px;
}

.row {
  .col-6 {
    @include tablet {
      margin-left: 24px;
      width: calc(50% - 12px);
    }
  }

  .col-3 {
    @include tablet {
      margin-left: 24px;
      width: calc(25% - 18px);
    }
  }
}

form {
  .row [class*='col-'] {
    &:not(:first-child) {
      margin-top: 0px;
    }
  }

  .read-only .row [class*='col-'] {
    margin-top: 20px;
    @include tablet {
      margin-top: 0px;
    }
  }
}
