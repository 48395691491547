// Typography Variables
// ========================================

  // Font Families

  @font-face {
    font-family: 'Univers-Regular';
    src: url('../fonts/UniversLTStd.woff') format('woff'), 
         url('../fonts/UniversLTStd.otf') format('otf')
    ;
  }

  @font-face {
    font-family: 'Univers-Bold';
    src: url('../fonts/UniversLTStd-Bold.otf') format('otf'),
    url('../fonts/UniversLTStd-Bold.woff') format('woff');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Univers-Light-Condensed';
    src: url('../fonts/UniversLTStd-LightCn.otf') format('otf'),
    url('../fonts/UniversLTStd-LightCn.woff') format('woff');
  }

  @font-face {
    font-family: 'Univers-Bold-Condensed';
    src: url('../fonts/UniversLTStd-BoldCn.otf') format('otf'),
    url('../fonts/UniversLTStd-BoldCn.woff') format('woff');
  }


  $primary-sans-serif: 'Univers-Regular', sans-serif;
  $primary-sans-serif-bold: 'Univers-Bold', sans-serif;
  $primary-sans-serif-condensed: 'Univers-Light-Condensed', sans-serif;
  $primary-sans-serif-condensed-bold: 'Univers-Bold-Condensed', sans-serif;

  // Sizes
  $font-size-base: 16px;

  $s-giga:    60px;
  $s-mega:    48px;

  $s-huge:    36px;
  $s-large:   27px;
  $s-bigger:  22px;
  $s-big:     18px;
  $s-medium:  16px;
  $s-base:    15px;
  $s-small:   11px;
  $s-tiny:    10px;

  // Line Heights
  $base-line-height: $font-size-base * 1.9;
  $base-line-loose: $font-size-base * 2.2;
  $base-line-small:  $font-size-base;

  // Font Weights
  $regular: 400;
  $semibold: 600;
  $bold: 700;